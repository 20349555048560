.testimonial-grid-title {
	@include h2-bar;
}

.testimonial-name {
		@extend %h3;

		@include until( $tablet ){
			font-size: 20px;
		}
}

.testimonial-grid {
	background: $primary-color-light;
	padding: 50px 14px;
	position: relative;

	@include desktop {
		padding: 100px 178px;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		height: calc( 100% - 120px );
		width: calc( 100% - 48px );
		top: 60px;
		background: $white;
		border: solid 2px;
		border-color: $primary-color;
		box-shadow: 0 0 0 10px $white;
		left: 50%;
		transform: translateX( -50% );

		@include desktop {
			height: calc( 100% - 200px );
			width: calc( 100% - 356px );
			top: 110px;
		}

		@media screen and ( min-width: 1440px )  {
			max-width: 1060px;
		}
	}

	.testimonial-grid-title {
		position: relative;
		z-index: 10;
		display: none;

		@include desktop {
			display: block;
			margin-top: 165px;
		}
	}

	.testimonial-carousel {
		padding: 12px;

		@media screen and ( min-width: 1440px )  {
			max-width: 1060px;
			padding: 12px 0;
			margin: 0 auto;
		}
	}

	.testimony-blockquote {
		padding: 36px 22px;
		position: relative;

		@include desktop {
			margin-bottom: 112px;
		}

		&::before {
			color: $secondary;
			content: '\201c';
			font-family: 'Prata', serif;
			font-size: 120px;
			text-align: center;
			line-height: 1;
			height: .3em;
			display: block;
			margin-bottom: 25px;

			@include desktop {
				font-size: 200px;
				position: absolute;
				top: 180px;
				left: 10%;
				text-align: left;
			}
		}
	}

	.testimonial-image {
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		margin: 0 auto 34px;

		@include tablet {
			width: 130px;
			height: 130px;
		}
	}

	.testimonial-title {
		display: none;
	}

	.testimonial-text {
		margin-bottom: 35px;
	}

	.testimony-blockquote blockquote {
		text-align: center;

		@include tablet {
			margin-left: 21%;
			margin-right: 17%;
		}
	}



	.blockquote-name {

		&::before {
			content: '- ';
		}

		// &::after {
		// 	content: '.';
		// }
	}


	.slick-arrow {
		@include until( $desktop ){
			display: none!important;
		}

		@include desktop {

			&.slick-next {
				right: -80px;
			}
			
			&.slick-prev {
				left: -80px;
			}
		}
	}

	.slick-dots {
		margin: 0;
		bottom: -40px;
		left: 0;

		@include desktop {
			bottom: -70px;
		}
	}
}