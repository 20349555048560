.contact-map-details-group {
	@include mobileSidePadding;
}

.contact-map {

	@include tablet {
		min-height: 0;
	}

	.contact-map-details {
		padding: 50px 34px 40px;
		border-top: none;
		background: $primary-color;
		color: $primary-color-invert;

		@include tablet {
			width: auto;
			align-items: flex-start;
			flex: 1 1 60%;
			max-width: 550px;
		}

	}

	.contact-map-details-title {
		margin-top: 26px;
		margin-bottom: 6px;
	  }

	.contact-map-details-title, 
	.contact-map-details-clinic-name, 
	.contact-map-details-phone-general a,
	.contact-map-details-email a,
	.contact-map-details-location-title, 
	.contact-map-details-hours-title
	{
		color: $primary-color-invert;
	}
	
	.contact-map-details-phone-emergency a,
	.contact-map-details-phone-general a,
	.contact-map-details-email a {
		color: $white;
		&:hover {
			color: $primary-color-light;
		}
	}

	.contact-map-details-title {
		margin-bottom: 24px;
	}

	.contact-map-details-clinic-name {
		font-family: 'Lato', sans-serif;
		font-size: 22px;
		margin-bottom: 48px;
	}

	.contact-map-details-group {
		@include until( $tablet ){
			padding-left: 10px;
			padding-right: 10px;
		}

		p, h4, a {
			font-family: 'Lato', serif;
			font-weight: 700;
			font-size: 16px;
			text-align: left;
			font-size: 16px;
		}

		> div {
			margin-bottom: 30px;
		}

		p {
			line-height: 1.4;
		}

	}

	.contact-map-details-group div.contact-map-details-phone-emergency {
		background: none;
		padding: 0;

		&:hover {
			background: none;
		}
	}

	.contact-map-details-email {
		word-break: break-all;
	}

	.contact-map-details-email a {
		text-decoration: underline;
	}


	.column.contact-map-map {
		@include until($desktop){
		margin-top: 0;
		}
	}

	.contact-map-details-location-copy span {
		@include tablet {
			display: block;
		}
	}

	@include desktop {
		.contact-map-details {
			justify-content: center;
		}

		.contact-map-details .contact-map-details-group > div::before {
			content: none;
			padding-left: 3em;
		}
	}

	#g-map {
		position: relative;

		iframe {
			mix-blend-mode: luminosity;
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0; 
			left: 0;
			background: $primary-color;
			z-index: -10;
        }
    }

    @include desktop {
        .contact-map-details-group > div {
            padding: 0;
        }
    }
    
    &.default-map {

        @include tablet {
            .contact-map-details-column {
               width: 100%;
               height: auto;
               padding: 25px 0 !important;
               background: transparent !important;
               justify-content: flex-start;
           }
           .contact-map-details {
               padding: 64px 80px 64px 80px;
               width: auto;
               height: auto;
               margin: 100px 0 100px 7%;
           }
   
           .contact-map-details-group {
               padding: 0;
           }
   
           .contact-map-map {
               position: absolute;
               width: 100%; 
               height: 100%;
               top: -25%;
               left: 0;
               z-index: -1;
           }
   
           #g-map {
               height: 150%;
               width: 150%;
               width: calc( 100% + 470px );
           }
           
       }
    }

    &.multisite,
    &.embeded {

			.contact-map-details {
        .contact-map-details-phone-emergency,
        .contact-map-details-phone-general,
        .contact-map-details-email,
        .contact-map-details-location,
        .contact-map-details-hours {
            &:before {
                content: none;
            }
        }

    }

        .contact-map-details-group h4,
        .multisite-location-social-item a {
            color: $white;
        }

        .contact-map-details-column {
            background: $primary-color;

            @include desktop {
                border-bottom: solid 1px;
                border-bottom-color: $primary-color-light;
            }
        }

        .contact-map-details-phone-emergency p,
        .contact-map-details-phone-general p {
            @extend %body-2;
        }

        @include tablet {
            .contact-map-details-group {
                padding: 0;
                align-self: flex-start;
            }
        }

        @include tablet-only {
            .contact-map-details {
                align-items: center;
            }
        }
    }

}
