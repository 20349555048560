$family-primary: 'Lato', sans-serif;

body {
	font-family: 'Lato', sans-serif;
	color: $neutral-dk;
}

h1, .h1, .hero-text, %h1, %hero-text {
	font-family: 'Prata', serif;
	font-weight: 400;
	font-size: 42px;
	letter-spacing: -.04em;
	line-height: 1.1;
	text-align: center;
	color: $primary-color;

	@include tablet {
		font-size: 70px;
	}
}

h2, .h2, %h2 {
	font-family: 'Prata', serif;
	font-weight: 400;
	font-size: 24px;
	line-height: 1.3;
	letter-spacing: -.02em;
	color: $primary-color;
	text-align: center;
	margin-bottom: .22em;

	@include tablet {
		font-size: 44px;
	}
}

h3, .h3, %h3 {
	font-family: 'Prato', serif;
	font-weight: 400;
	font-size: 24px;
	line-height: 1.07;
	letter-spacing: -.02em;
	color: $primary-color;
	margin-bottom: .37em;

	@include tablet {
		font-size: 27px;
	}
}

h4, .h4, .contact-text, %h4, %contact-text {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 1.1;
	letter-spacing: .09em;
	color: $neutral-dk;
	text-transform: uppercase;
	margin-bottom: .45em;

	@include tablet {
		font-size: 22px;
	}
}

h5, .h5, .page-title, %h5, %page-title  {
	font-family: 'Inconsolata', serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 1;
	letter-spacing: .11em;
	color: $black;
	text-transform: uppercase;

	@include tablet {
		font-size: 20px;
	}
}

h5, .h5, %h5 {
	margin-bottom: .45em;
}

.body-intro, %body-intro {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.8;
	color: $neutral-dk;
	margin-bottom: .5em;

	@include tablet {
		font-size: 20px;
	}
}

p, .body-1, %body-1 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 2.1;
	margin-bottom: .6em;
	
	@include tablet {
		font-size: 19px;
	}
}

.body-2, %body-2 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.7;
	margin-bottom: .7em;
	
	@include tablet {
		font-size: 16px;
	}
}

%button-text, .link {
	font-family: 'Inconsolata', serif;
	font-weight: 700;
	font-size: 12px;
	line-height: 1;
	letter-spacing: .09em;
	text-transform: uppercase;

	@include tablet {
		font-size: 16px;
	}
}


%hover-highlight {
	&:hover {
		background: linear-gradient( $white 50%, $primary-color-light 50%, $primary-color-light 80%, $white 80% );
	}
}

.button, %button-extend {
	@extend %button-text,
	%hover-highlight;
	padding: 1.15em 2.5em 1em;
	border-radius: 0;
	text-decoration: none;
	z-index: 1;

	&:hover {
		border-color: inherit;
	}
}

.button-primary, 
a.button-primary, 
.button.is-primary, 
.button, 
%button-primary {
	@extend %button-extend;
	position: relative;
	color: $primary-color-invert;
	background: $primary-color;
	border-color: $primary-color;

	a {
		color: $primary-color-invert;
		text-decoration: none !important;
	}

	&:hover {
		// background: $white;
		border-color: $primary-color;
		color: $black;
		a {
			color: $black;
		}

	}
}

.button-secondary, a.button-secondary, %button-secondary {
	@extend %button-extend;
	background: $white;
	color: $black;
	border: solid 1px;
	border-color: $primary-color;
	position: relative;
	z-index: 1;
	text-decoration: none;

	&:hover {
		color: $black;
		border-color: $primary-color;
	}

	a {
		color: $black;
		text-decoration: none !important;
	}
 
}

%button-outlined-white {
	@extend %button-extend;
	border-color: $white;
	color: $white;
	background: transparent;

	&:hover {
		border-color: $white !important;
		background: $white;
		color: $black;
	}
}



.link {
	// text-decoration: underline;
	&::after {
		// font-family: 'pathway-icons';
		// content: '\f179';
		// position: relative;
		// // padding-left: 2px;
		// vertical-align: middle;
	}
}

@mixin h2-bar {
	position: relative;
	letter-spacing: initial;
	padding-top: 20px;
	@include tablet {
		padding-top: 52px
	}
	
	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 3em;
		height: 4px;
		background-color: $secondary;
		top: 0;
		left: 50%;
		transform: translateX( -50% );

		@include tablet {
			width: 5em;
		}
	}
}

.module-shim {
	background-color: $tertiary-light;
}

.generic-content {
	padding-top: 80px;
}

.base-content {
	h1 {
		margin-bottom: 1em;
	}

	h2, h3, h4 {
		margin-top: 1em;
	}

	h2 {
		text-align: left;
	}

	li {
		@extend %body-1;
		margin-bottom: 1em;
	}

	blockquote {
		margin-top: 4em;
		margin-bottom: 4em;
		display: flex;

		&::before {
			color: $primary-color;
			font-family: 'Prata', serif;
			content: '\201c';
			font-size: 10em;
			line-height: 1.1;
			float: left;
			margin-right: 16px;
			height: .5em;
		}

		&.blockquote-block, & p {
			@extend %body-1;
			text-transform: uppercase;
			letter-spacing: .1em;
			line-height: 1.6;
		}
	}
} 