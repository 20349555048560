section.error {

	.error-image {
		position: relative;
		z-index: 1;
		width: 70%;
		max-width: 180px;

		svg {
			position: relative;
			z-index: 5;
				path {
				fill: $neutral-dk;
			}
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			width: 130%;
			height: 130%;
			border-radius: 50%;
			background: $primary-color-light;
			right: -15%;
			top: -15%;
		}
	}

	.error-cta {
		@extend %button-secondary;
	}
 
} 