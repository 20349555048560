.three-col-carousel {
	background: $tertiary-dark;
	padding: 50px 0 100px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include tablet {
		padding: 125px 0 143px;
	}

	@include desktop {
		padding: 200px 0 186px;
	}

	.three-col-carousel-title {
		text-align: center;
		color: $white;
		@include mobileSidePadding;
		margin-bottom: 50px;

		@include tablet {
			margin-bottom: 80px;
		}
	}

	.three-col-carousel-description {
		color: $white;
		margin-top: -3em;
		margin-bottom: 4em;
	}

	.slick-arrow {
		display: none!important;
	}

	.container {
		width: 100%;
		flex: 0 0 auto;
	}

	.affiliate-logo {
		width: 50vw;
		height: auto;		
		margin-left: auto;
		margin-right: auto;

		@include tablet {
			border-right: solid 1px;
			border-right-color: $white;
			// width: 20vw;
			// height: auto;

			&:last-of-type {
				border-right: none;
			}
		}

		img {
			display: block;
			margin: 0 auto;
			max-width: 400px;
			max-height: 200px;
			width: auto;
			height: auto;
		}
	}

	.slick-slider.slick-dotted .slick-dots {
		@extend %white-dots;
		bottom: -70px;
	}
}