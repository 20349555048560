.photo-gallery {

    .photo-gallery-modal-caption {
        position: relative;
        margin: 0;
        text-align: left;
        padding: 70px 60px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            border: solid 6px;
            border-color: $secondary;
            width: 100%;
            height: 100%;
            width: calc( 100% - 12px );
            height: calc( 100% - 12px );
            top: 6px;
            right: 6px;
        }
    }

    .photo-gallery-modal-title {
        margin-bottom: 26px;
    }

    .lightbox-modal {
        background: none;
        padding-top: 20px;
    }



    .modal-slide-close {
           display: none;
    }
    
    .modal-slide {
        padding: initial;
    }

    .modal-background {
        cursor: pointer;

        &::before {
            font-family: 'pathway-icons';
            content: "\e870";
            font-size: 20px;
            line-height: 1;
            position: absolute;
            display: block;
            width: 640px;
            height: 20px;
            top: 10px;
            right: 20px;
            color: $white;
            z-index: 100;
            margin-left: auto;
            margin-right: auto;
            right: 0;
            left: 0;
            text-align: left;
            display: flex;
            justify-content: flex-end;
        }

        &:hover::before {
            color: $secondary;
        }
    }

    @include until( $tablet ){
        .photo-gallery-modal-image {
            background: none;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 80vh;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .modal-content-slider {
            margin: 0;
        }

        .photo-gallery-modal-caption {
            margin: -20px 5% 0;
            padding: 34px 22px;

            &::after {
                border: 2px solid;
                border-color: $secondary;
                width: calc( 100% - 20px );
                height: calc( 100% - 20px );
                top: 10px;
                left: 10px;
            }
        }

        .photo-gallery-modal-title {
            margin-bottom: 12px;
        }

        .modal-background::before {
            width: 100%;
            margin-right: 20px;
        }
    }

    &.image-gallery-text {

        .photo-gallery-modal-image {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        .photo-gallery-modal-caption {
            color: $neutral-dk;
            font-size: 10px;


            @include tablet {
                font-size: 12px;
            }

            &::after {
                // content: none;
            }

        }

        .modal-background::before {
            width: 100%;
            max-width: 1200px;
            font-size: 32px;
        }
    }

    @include tablet {
        .slick-list {
            height: 100%;
        }

        .modal-content {
            height: calc(100vh - 80px);
            max-height: none;
            overflow: hidden;
        }

        &.card-grid .slick-track .slick-slide {
            overflow-y: scroll;
        }

        .photo-gallery-modal-image {
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 85vh;
                margin-left: auto;
                margin-right: auto;
            }
        }

    }

}