    .modal-slide-close, .modal-slide-close:hover {
        border: 0;
        background: none;
    }

    .modal-slide-close {
        &::after, &::before {
            background-color: $secondary;
        }
    }

    .modal-slide-close:hover {
        &::after, &::before {
            background-color: $primary-color;
        }
    }
