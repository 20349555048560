.navbar-cta {
	@extend .container;
}

.header {
	@include mobileSidePadding;
	padding-top: 24px;

	@include desktop {
		padding-bottom: 42px;

		.menu-item .p-icon {
			display: none;
		}
	}
	
	.navbar {
		@include desktop {
			align-items: flex-end;
		}
	}

	.navbar-cta {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 20px;

		@include until( $desktop ){
			display: none;
		}

		.pharma-link,
		.requestAppt-cta {
			font-size: 13px;
		}
	}

	.main-logo {
		padding: 0;

	}

	.navbar-brand {
		@include until( $desktop ){
			margin-bottom: 20px;
		}

	}

	.navbar-menu {
		align-items: center;
		box-shadow: none;
	}

	.menu-item, .appointment-cta-2 a {
		@include until( $desktop ){
			width: 90%;
			max-width: 570px;
		}

	}

	.menu-item {
		@include until( $desktop ){
			display: block;
			border: solid 1px;
			border-color: black;
			margin: 0 auto 15px;

			&.navbar-item {
				padding-top: 0;
				padding-bottom: 0;
			}

			&.has-dropdown .menu-link {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: solid 1px;
				margin-bottom: -1px;
				border-bottom-color: $black;
				vertical-align: middle;
				padding-left: 40px;

				& .p-icon {
					flex: 0 0 50%;
					text-align: right;
					height: 100%;
					padding: 12px 40px 0 0;
				}
			}

			&.no-dropdown {
				display: flex;
				justify-content: flex-start;
				padding-left: 0;
				padding-right: 0;

				a {
					justify-content: flex-start;
					width: 100%;
					padding-left: 40px;
				}
			}
		}

		@include desktop {
			padding: 0;
		}
	}


	.menu-link {
		@extend %button-extend;
		border-color: $black;
		font-size: 16px;

		@include until( $desktop ){
			border: none;
			padding: 0;
			height: 40px;
		}

		@include desktop {
			margin-left: -1px;
		}

	}

	.dropdown-link {
		color: $black;

		&:hover {
			background: none;
			color: $secondary;
		}
	}

	.navbar-dropdown {
		@extend %button-text;

		@include desktop {
			border-radius: 0;
			border: solid 1px;
			border-color: $black;
			box-shadow: none;
			margin-top: -1px;
			margin-left: -1px;
		}
	}


	.navbar-item .navbar-link.is-current,
	.navbar-item .navbar-link.is-current:hover,
	.navbar-item .dropdown-item.is-current,
	.navbar-item .dropdown-item.is-current:hover  {
		background: $primary-color-light;
	}

	.dropdown-item {
		padding: 0;
	}

	.dropdown-link {
		display: block;
		width: 100%;
		padding: 10px 40px;
		font-size: 14px;
	}

	.menu-item.has-dropdown .menu-link {
		&::after {
			content: none;
		}

		.p-icon {
			flex: 0 0 50%;
			text-align: right;
		}
	}

	.appointment-cta-2,
	.online-pharmacy-mobile {
		@include desktop {
			display: none;
		}
	}

	.requestAppt-cta {
		@extend %button-primary;
	}

	.appointment-cta-1 {
		margin-right: -12px;
		margin-left: 26px;
	}


	.appointment-cta-2 a {
		display: block;
		margin: 50px auto;
	}

	.pharma-link {
		@extend %button-text;
		color: $black;

		&:hover {
			color: $secondary;
		}
	}

	.online-pharmacy-mobile  {
		text-align: center;
		margin-bottom: 34px;
		a {
			font-size: 16px;
		}
	}
}

.fixed-header {

	.main-nav {
		padding-right: 1px;
	}

	.header .menu-link {
		border: none!important;
		border-width: 0px!important;
		box-shadow: 0 0 0 1.3px #000!important;
	}

	@include desktop {

		.header .menu-link,
		.requestAppt-cta {
			padding: .8em 1.5em .7em;
		}
	
		.requestAppt-cta {
			margin-right: -1px;
		}
	}

}