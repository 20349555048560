.social-reviews {
	background: $tertiary-dark;

	.social-reviews-title {
		color: $white;
	}

	.social-reviews-content {
		position: relative;

		&::before {
			position: absolute;
			content: "";
			width: calc( 100% - 8px );
			height: calc( 100% - 8px );
			top: 4px;
			left: 4px;
			border: solid 1px;
			border-color: $tertiary-dark;
		}
	}

	.social-reviews-author {
		font-size: 24px;
	}

	.social-reviews-date {
		@extend %h5;
		font-size: 16px;
		color: $primary-color;
	}

	.social-reviews-body {
		@extend %body-2;
		color: $neutral-dk;
	}

	.slick-slider.slick-dotted .slick-dots {
		@extend %white-dots;
	}

	button.slick-arrow {
		&::after {
			background-color: transparent;
			border: solid 1px;
			border-color: $white;
		}

		&:hover {
			&::after {
				background-color: $white;
				color: transparent;
			}
			&::before {
				color: $tertiary-dark;
			}
		}
	}

}
