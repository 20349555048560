.promo-grid, .referring-vets {
    background: none;

    @include tablet {
        padding-top: 100px;
    }

    .card-grid-section-title {
        @include h2-bar;
        color: $primary-color;
        margin-bottom: 50px;  

        @include tablet {
            margin-bottom: 60px;
        }

        @include desktop {
            margin-bottom: 90px;
        }
    }

    .promo-grid-container.slick-slider {
        margin-bottom: 0;
    }

    .card-grid-item {
        align-items: center;
        background: $primary-color-light;
        color: $primary-color;
        padding: 34px 24px 28px;

        @include tablet {
            padding: 64px 42px;
        }

    }

    .card-grid-title,
    .card-grid-offer {
        margin-bottom: 14px;
    }

    .card-grid-title {
        @extend %h4;
        font-size: 11px;

        @include tablet {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    .card-grid-offer {
        @extend %h2;
        font-size: 24px;
        letter-spacing: .03em;
        font-weight: 400;
        color: $primary-color;

        @include tablet {
            font-size: 44px;
        }
    }

	.card-grid-description,
	.card-grid-description p {
        @extend %body-2;
        color: $neutral-dk;
        margin-bottom: 18px;
        padding: 0 14px;

        @include tablet {
            padding: 0;
            margin-bottom: 46px;
        }
    }

    .card-grid-link {
        @extend %button-secondary;
        text-decoration: none;
        display: inline-flex;
        width: 100%;
        max-width: 400px;
        margin-bottom: 0;

        @include tablet {
            width: auto;
            max-width: 100%;
        }

        @include widescreen {
            min-width: 250px;
        }
    }

    .slick-dots {
        bottom: -44px;
    }

    .slick-slide {
        width: 250px;
        margin: 0;
    }


    .slick-arrow {
        display: none!important;
    }

    .slick-slide {
        transform: scale(.9);
        transition: .3s;

        &.slick-current {
            transform: scale(1);
        }
    }
}

.referring-vets.card-grid {

    .referring-vets-section-title {
        margin-bottom: 34px;

        @include tablet {
            margin-bottom: 86px;
        }
    }

    .referring-vets-container {
        @media (min-width: $tablet){
        display: flex;
        flex-direction: row;
        // overflow: hidden;
        justify-content: space-between;
        flex-wrap: wrap;
        }
        
        @include tablet-only {
        padding-left: $side-padding;
        padding-right: $side-padding;
        }
    }

    .referring-vets-title {
        @extend %h3;
        text-transform: none;
    }

    .referring-vets-item {
		border: none; 
		
        @include until( $tablet ){
            width: 90%;
            max-width: 480px;
            margin: 0 auto 16px;
        }
    }

    .referring-vets-link {
        @include until( $tablet ){
            width: auto;
        }
    }
}
