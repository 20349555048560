.hero-carousel-alt.hero-carousel {
	background: transparent;
	overflow: hidden;
	
	.hero-carousel-card {
		position: relative;
		display: flex !important;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;  
		height: 350px; 

		@include tablet {
			height: 550px;
		}
	
		@include desktop {
			height: 700px;
		}
	}
	
	.hero-carousel-image-wrap::after {
		content: '';
		height: -webkit-fill-available;
		position: absolute;
		width: 100%;
		background: rgba(0, 0,0, .6);
		top: 0;
		left: 0;
		z-index: -1;
	}

	.hero-carousel-image-wrap {
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			max-width: none;
			object-fit: cover;  
			@include object-fit( cover, top );
		}
		&::after {
			width: 100%;
			height: 100%;
			z-index: 0;
		}
	}

	.hero-carousel-text {
		text-align: center;
		width: 100%;
	}

	.hero-carousel-title {
		@extend %h2;
		color: $white;
	}

	.hero-carousel-title {
		margin-bottom: 24px;

		@include tablet {
			margin-bottom: 65px;
		}
	}

	.hero-carousel-subtitle {
		display: block;
	}

	.hero-carousel-cta {
		@extend %button-primary;
		border-color: $white;
	}

	.slick-arrow {
		display: none !important;
	}

	.slick-slider.slick-dotted .slick-dots {
		margin-top: 0;
		bottom: 0;
		@extend %white-dots;

		@include tablet {
			bottom: 60px;
		}
	}

}

