.blog-info, .blog-content {
	@extend .is-12;
}

.module-blog {
	padding: 60px 0 54px;
	
	.blog-title-1 {
		@extend %h2;
		padding-left: $side-padding;
		padding-right: $side-padding;
	}

	.blog-title-2 {
		display: none;
	}

	.blog-hero-image {
		max-height: 260px;
		border-bottom: none;
		margin-bottom: 20px;

		@include tablet {
			max-height: 560px;
		}

		img {
			object-fit: cover;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );
			width: 100%;
			height: 100%;
		}
	}

	.blog-byline {
		margin-bottom: -10px;
	}

	.blog-byline p {
		@extend %page-title;
		font-size: 12px;
		color: $black;
		text-align: left;

		span {
			display: block;
		}

		.blog-author {
			margin-bottom: 12px;
			&::after {
				content: none;
			}
		}

		.blog-date {
			color: $black;
		}
	}

	.blog-social-pre {
		display: none;
	}

	.blog-content {
		text-align: left;
	}

	.blog-social {
		justify-content: flex-start;
		align-items: flex-end;
	}

	.blog-social-title {
		@extend %button-text;
		padding-bottom: 2px;
		color: $primary-color;

		&::after {
			content: ":";
		}
	}

	.blog-social-link {
		

		.p-icon {
			color: $neutral-lt;
			vertical-align: bottom;

			&:hover {
				color: $primary-color;
			}
		}
	}

	@include tablet {
		padding: 128px $side-padding 90px;
				
		.blog-hero-container, .blog-container, .blog-title-1, .hero-caption {
			width: 100%;
			max-width: 1056px;
			margin-left: auto;
			margin-right: auto;
		}

		.blog-title-1 {
			margin-bottom: 46px;
		}

		.blog-hero, .blog-hero-image {
			margin: 0;
		}

		.blog-hero-caption, .blog-container {
			padding-left: 80px;
			padding-right: 80px;
			margin-bottom: 0;
			border-left: solid 1px;
			border-right: solid 1px;
			border-color: $secondary;
		}

		.blog-hero-caption {
			padding-top: 18px;
			padding-bottom: 90px;
		}

		.blog-container {
			border-bottom: solid 1px;
			border-color: $secondary;
			padding-bottom: 80px;
		}

		.blog-byline  p{
			font-size: 20px;
			margin-bottom: 56px;
		}

		.blog-article {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@include desktop {
		padding-left: 0;
		padding-right: 0;

		.blog-hero-caption, .blog-container {
			padding-left: 155px;
			padding-right: 155px;
		}
	}

	&.blog-no-hero {
		.blog-title {
  
		  @include tablet {
			  border: solid 1px;
			  border-color: $secondary;
			  border-bottom: none;
			  margin-bottom: 0;
			  padding-top: 80px;
			  padding-bottom: 100px;
		  }
		}
	}

}