.resource-links-li {
	@extend .is-4-desktop;
}

.resource-links {
	background: $secondary;
	color: $secondary-invert;

	.container {
		// max-width: 1152px;
	}
	.resource-links-title {
		@extend %h2;
		color: $secondary-invert;
		margin-bottom: 20px;

		@include tablet {
			margin-bottom: 50px;
		}
	}

	.resource-links-description {
		@include mobileSidePadding;
		@extend %body-1;
		max-width: 48em;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
		@include tablet {
			margin-bottom: 80px;
		}
	}

	.resource-links-list {
		@include until($widescreen){
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.resource-links-li {
		height: auto;
		margin-bottom: -12px;

		@include tablet {
			margin-bottom: 22px;
		}
	}

	.resource-links-li a {
		@extend %body-1;
		@extend %hover-highlight;
		text-transform: uppercase;
		line-height: 1.2;
		background-color: $white;
		color: $neutral-dk;
		padding: 28px 10px 28px 20px;
		display: inline-flex;
		align-items: center;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		text-align: left;
		position: relative;
		font-weight: 700;

		@include until( $tablet ){
			max-width: 440px;
		}

		@include tablet {
			width: 100%;
			padding: 20px 20px 20px 20px;
			height: 100%;
			max-width: none;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1 1 auto;
		}

		& .p-icon {
			color: $secondary;
			text-decoration: none;
			display: block;
			position: relative;
			font-size: 250%;
			// left: 20px; 
			margin-right: 20px;
			// top: 50%;
			// transform: translateY(-50%);
			@include tablet {
				position: relative;
				left: 0;
				flex: 0 1 15%;
				margin-right: 5%;
			}
			@include desktop {}
		}

		& span::after {
			font-family: "pathway-icons";
			content: "\2002\e876";
			font-size: 80%;
			display: inline;
			// padding-left: .5em;

			@include tablet {
				// padding-left: 1em;
				flex: 0 1 30px;
			}
		}

	}


}