.page-header, .page-header-image {
	height: 190px;

	@include tablet {
		height: 285px;
	}

	@include desktop {
		height: 380px;
	}

}

.page-header {
	@include maximize-width;
	padding: 0;
	position: relative;
	background: lightgray;
	background-image: url(/img/theme-1-headers/page-header-team.png);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;
		background-color: $black;
		opacity: .5;
	}

	> .container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		z-index: 10;
		height: 100%;
	}

	h1.page-title {
		text-align: center;
		flex: 0 0 auto;
		padding: 24px 32px 20px;
		// margin-bottom: 20px;
		background: $white;
		width: 90%;
		max-width: 634px;
	}
	
	.standardpage &,
	.genericcontent & {
		margin-bottom: 80px;
	}

}

//User Page Header
section.page-header.page-header-background {
	background-image: none;

	> .container {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		max-width: none;

		h1.page-title {
			@extend .container;
			text-align: center;
			flex: 0;
			width: 90%;
			max-width: 634px;
		}
	}

	.page-header-image {
		position: relative;
		@include maximize-width;
		overflow: hidden;

		img {
			object-fit: cover;
			@include object-fit( cover );
			min-height: 100%;
		}

	}

}


//Page specific headers
.page-header {
	 .financing & {
		background-image: url(/img/theme-1-headers/page-header-financing.png);
	}
	 .about & {
		background-image: url(/img/theme-1-headers/page-header-about.png);
	}
	 .blog & {
		background-image: url(/img/theme-1-headers/page-header-blog.png);
	}
	 .teams & {
		background-image: url(/img/theme-1-headers/page-header-team.png);
	}
	 .feedback & {
		background-image: url(/img/theme-1-headers/page-header-feedback.png);
	}
	 .feedback & {
		background-image: url(/img/theme-1-headers/page-header-feedback.png);
	}
	 .photo & {
		background-image: url(/img/theme-1-headers/page-header-photos.png);
	}
	 .community & {
		background-image: url(/img/theme-1-headers/page-header-community.png);
	}
	 .services & {
		background-image: url(/img/theme-1-headers/page-header-services.png);
	}
	 .specials & {
		background-image: url(/img/theme-1-headers/page-header-specials.png);
	}
	 .resources & {
		background-image: url(/img/theme-1-headers/page-header-resources.png);
	}
	 .resources & {
		background-image: url(/img/theme-1-headers/page-header-resources.png);
	}
	 .contact & {
		background-image: url(/img/theme-1-headers/page-header-contact.png);
	}
}
