// Clinic Colors
$primary-color: "%%Primary%%";
$secondary:"%%Secondary%%";
$tertiary: "%%Tertiary%%"; 

// Theme Neutrals
$white: #fff;
$black: #000;
$neutral-dk: #343434; 
$neutral-lt: #727272;

@import "../../variables/color-functions-base"; // append -base for color replacement

// Bulma Overrides
$text: $neutral-dk;
