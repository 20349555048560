.generic-cta-button {
    @extend %button-outlined-white;
}

.generic-cta-title {
    @include h2-bar;
    color: $secondary-invert;
    &::before {
        background: currentColor;
    }
}

.generic-cta {
    @include mobileSidePadding;
    padding-top: 50px;
    padding-bottom: 50px;
    background: $secondary;
    color: $secondary-invert;
    text-align: center;

    @include tablet {
        padding: 105px 0;
    }

    @include desktop {
        padding: 160px 0;
    }

    .generic-cta-container {
        background: none;
        border: none;
        padding: 0;
    }

    .content-copy {
        max-width: 42em;
        margin-left: auto;
        margin-right: auto;
    }

    .generic-cta-button {
        min-width: 176px;

        @include tablet {
            min-width: 300px;
        }
    }
}

.generic-cta.generic-cta-alt {
    background: none;
    color: $neutral-dk;
    padding-top: 38px;
    padding-bottom: 50px;

    // @include tablet-only {
    //     padding-left: $side-padding;
    //     padding-right: $side-padding;
    // }

    @include tablet {
        padding: 100px $side-padding;
    }

    .generic-cta-container {
        padding: 64px 24px 50px;
        border: solid 1px; 
        border-color: $black;
        max-width: 1200px;
        margin: 0 auto;

        @include tablet {
            padding-top: 80px;
            padding-bottom: 106px;
        }
    }

    .generic-cta-title {
        color: $primary-color;

        &::before {
            color: $secondary;
        }
    }

    .content-copy {
        @include tablet {
            margin-bottom: 50px;
        }
    }

    .generic-cta-button {
        @extend %button-secondary;
        margin-top: 0;
    }
}

.onColInfo-no-frame .generic-cta .generic-cta-container {
    border: none;

}