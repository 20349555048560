.half-column-title {
	@include h2-bar;
}

.half-column-cta {
	@extend %button-secondary;
}

.half-column-container {
	@include mobileSidePadding;
}

.two-column-promo {
	padding: 20px 0 80px;

	@include tablet {
		padding: 116px 0 116px;
	}

	.two-column-promo-image.column {
		margin-right: 4%;
		margin-top: 4%;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 90%;
			height: 90%;
			background: $secondary;
			top: -4%;
			right: -4%;
			position: absolute;
			z-index: -1;
		}
		
		// @include tablet {
		// 	margin-right: 18px;
		// 	margin-top: 18px;

		// 	&::before {
		// 		top: -18px;
		// 		right: -18px;
		// 	}
		// }
	}

	.two-column-promo-col.column .half-column-container {
		margin-top: 56px;
		width: 100%;

		@include tablet {
			margin: 0 5% 0;
			// padding: 1px;
		}

		.half-column-title {
			margin-bottom: 22px;
		}

		p.half-column-content {
			margin-top: 0;
			margin-bottom: 30px;
			max-width: 32em;
			width: 100%;
		}
	}

	.half-column-cta {
		min-width: 176px;

		@include desktop {
			min-width: 234px;
		}
	}
}

.two-column-promo.image-right {

	.two-column-promo-image.column {
		margin-top: 0;
		margin-right: 0;
		margin-bottom: 4%;
		margin-left: 4%;

		&::before {
			top: initial;
			left: initial;
			bottom: -4%;
			left: -4%;
			top: initial;
			right: initial;
		}
	}
}
