.featured-promo-outer-container {
    @extend .container;
}

.featured-promo-button.button {
    @extend %button-primary;
}

h2.feature-promo-title {
    @include h2-bar;
}

.featured-promo {
    padding: 50px 0 0 0;
    background: $primary-color-light;

    .featured-promo-outer-container {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            justify-content: space-around;
        }
    }

    .oneColPromoImg {
        order: 2;
        display: block;
        width: 80%;
        max-width: 610px;
        height: auto;
        margin: 0 auto;

        img {
            display: block;
        }

        @include tablet {
            order: 1;
            margin: 0;
            align-self: flex-end;
        }
    }

    .featured-promo-container {
        padding-top: 0;
        padding-bottom: 20px;
        border: none;
        background: none;
        text-align: center;

        @include tablet {
            order: 2;
            align-self: center;
            margin-left: 5%;
            margin-bottom: 20px;
        }
	}
	
	h2.feature-promo-title {
		letter-spacing: initial;
	}

    // h2.feature-promo-title {
    //     position: relative;
    //     letter-spacing: initial;
    //     padding-top: 20px;
        
    //     @include desktop {
    //         padding-top: 52px
    //     }
        
    //     &::before {
    //         content: '';
    //         display: block;
    //         position: absolute;
    //         width: 3em;
    //         height: 4px;
    //         background-color: $secondary;
    //         top: 0;
    //         left: 50%;
    //         transform: translateX( -50% );

    //         @include desktop {
    //             width: 5em;
    //         }
    //     }
    // }

    .featured-promo-container p.featured-promo-content {
        margin-bottom: 26px;
        max-width: 28em;
    }

    .featured-promo-container a.featured-promo-button {
        min-width: 184px;
        max-width: 90%;
        margin-top: 0;

        @include tablet {
            min-width: 252px;
        }
    }

    @include desktop {
        .featured-promo {
            padding: 0;
        }
        .featured-promo-container {
            padding: 0;
        }
    }
}