.three-column-general {
    background: $tertiary-dark;
    padding: 50px 0 100px;

    @include tablet {
        padding: 125px 0 143px;
    }

    @include desktop {
        padding: 200px 0 186px;
    }

    .three-column-general-title {
        color: $white;
        @include mobileSidePadding;
        margin-bottom: 50px;

        @include tablet {
            margin-bottom: 80px;
        }
    }

    .slick-arrow {
        display: none!important;
    }

    .container {
        width: 100%;
        flex: 0;
    }

    .affiliate-logo {
        img {
            display: block;
            margin: 0 auto;
        }

        @include tablet {
            border-right: solid 1px;
            border-right-color: $white;

            &:last-of-type {
                border-right: none;
            }
        }
    }

    .slick-slider.slick-dotted .slick-dots {
        @extend %white-dots;
        bottom: -70px;
    }
}