// t2 overrides
.form_builder {

  .datetimepicker {
    z-index: 10;
  }

  // aggressive button un-setter (bulma collision)
//   .datetimepicker-footer > * {
//     padding: inherit;
//     background: inherit;
//     border: inherit;
//     margin: inherit;
//     position: inherit;
//     font-size: inherit;
//   }

  .datepicker-nav-previous,
  .datetimepicker .datepicker-nav .datepicker-nav-next {
    // padding: inherit;
    // background: inherit;
    // border: inherit;
  }

//   .field + .form_divider {
//     margin-top: 1.75srem;
//   }

//   .form_divider {
//     margin-bottom: 0.5rem;
//   }

//   select {
//     min-height: 46px;
//   }
}
