.module-contact {
    background: $primary-color-light;
    padding-top: 20px;
    padding-bottom: 36px;

    @include tablet {
        padding-top: 120px;
        padding-bottom: 68px;
    }

    .contact-form-title {
        padding: 0 16px;
        text-align: left;
        margin-bottom: 36px;

        @include tablet {
            text-align: center;
            padding: 0 $side-padding;
            margin-bottom: 50px;
        }
    }

    .contact-control-submit {
        margin-bottom: 20px;

        @include tablet {
            margin-bottom: 28px;
        }
    }

    .contact-form-message {
        text-align: center;
    }

    .contact-notice {
        display: none;
    }

    .contact-image {
        display: none;
    }

}

.contactpage {
    .module-forms {
        background: $tertiary-light;
    }
}