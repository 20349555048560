.event-item.column.is-6-tablet {
	@extend .is-full-tablet,
	.is-full-desktop;
}

.events-grid {
	background: $primary-color-light;
	padding: 30px 0;

	@include desktop {
		padding: 130px 0 40px;
	}


	.event-item {
		padding-top: 0;
	}

	.event-item-content {
		display: flex;
		flex-direction: column;

		@include until( $desktop ){
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
		}

		@include desktop {
			flex-direction: row;
			flex-wrap: nowrap;
			margin-bottom: 80px;
		}
	}

	.event-thumbnail {
		margin-left: auto;
		margin-right: auto;
		border: solid 1px;
		border-color: $black;
		width: 100%;
		position: relative;

		@include until( $desktop ){
			padding-top: 100%;
			// height: 340px;
		}

		@include desktop {
			border-right: none;
		}

		@include desktop-only {
			flex: 0 0 40%;
		}
	
		@include desktop {
			flex: 0 0 34%;
			min-height: 400px;
		}

	}

	.event-image {
			display: block;
			object-fit: cover;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
	}

	.event-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		background: $white;
		border: solid 1px;
		border-color: $black;
		padding: 37px 30px 32px;
		margin: -14px 5% 35px;
		z-index: 10;

		@include desktop {
			margin: 0;
			text-align: left;
			align-items: flex-start;
			padding: 52px 50px 48px 44px;
		}
	}

	.event-title {
		order: 1;
		@extend %h3;
		margin-bottom: 32px;

		@include desktop {
			margin-bottom: 26px;
		}
	}

	.event-link {
		@extend %button-text;
	}

	.event-link-1 {

		@include until( $desktop ) {
			display: none;
		}
	}
	
	.event-link-2 {
		order: 5;
		@include desktop {
			display: none;
		}
	}

	.event-date {
		order: 2;
		@extend %page-title;
		margin-bottom: 16px;

		@include until( $desktop ){
			font-size: 12px;
		}

		@include desktop {
			margin-bottom: 28px;
		}
	}

	.event-add-calendar, .event-add-calendar-title {
		@extend %button-text;
		font-size: 12px;
		color: $primary-color;

		@include desktop {
			font-size: 14px;
		}
		
	}

	.event-add-calendar {
		order: 3;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		color: $secondary;
		margin-bottom: 28px;
		position: relative;
		overflow: hidden;

		@include desktop {
			order: 4;
			margin-bottom: 0;
			width: 100%;
			justify-content: flex-start;
		}

		@include desktop-only {
			padding-left: 160px;
			padding-bottom: 2em;
		}

		& .event-calendar-li, h4 {
			@include desktop {
				margin-bottom: 0;
			}
		}

		.event-calendar-li {
			flex: 1 1 30%;
			color: $secondary;
			position: relative;
			padding: 5px 0;
			border-right: solid 1px;
			border-right-color: $neutral-lt;
			margin-right: -1px;


			@include desktop {
				flex: 0 1 auto;
				text-align: center;
				padding: 4px 15px;
				font-size: 16px;
			}

			&:last-of-type {
				border-right: none;
			}

			a {
				color: $secondary;

				&:hover {
					color: $primary-color;
				}
			}
		}
	}

	.event-add-calendar-title {
		flex: 0 0 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Lato', sans-serif;
		text-transform: none;
		color: $neutral-dk;
		font-weight: 400;
		margin-bottom: 14px;

		&::before {
			font-family: 'pathway-icons';
			content: '\e922';
			font-size: 120%;
			padding-right: 10px;
			color: $secondary;
			top: -1px;
			position: relative;
		}

		@include desktop {
			flex: 0 1 auto;
			align-items: flex-start;
			justify-content: flex-start;
		}

		@include desktop-only {
			position: absolute;
			left: 0;
			top: 2px;
			width: 160px;
		}
	}



	p.event-body {
		order: 4;
		@extend %body-1;
		max-width: 38em;
		margin: 0 0 26px;

		@include desktop {
			max-width: none;
			order: 3;
			margin-bottom: 30px;
		}
	}


}
