%footer-text {
	font-family: 'Inconsolata', serif;
	font-weight: 700;
	text-transform: uppercase;
}

.newsletter-signup-title,
.newsletter-signup-button-nested,
.newsletter-signup-text,
.footer-links {
		@extend %footer-text;
}

.newsletter-signup-button {
	@extend %button-outlined-white;
	&:hover {
		border-left-color: $primary-color !important;
	}
}

.footer-copy p {
	@extend %body-2;
}

.newsletter-signup {
	border-color: $white;
}

.newsletter-signup-input {
	border-radius: 0;
	border: solid 2px;
	border-color: $white;
}

.social-bar {
	background: $primary-color;
	@include tablet {
		.container {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}
		.newsletter-signup-nested {
			display: flex;
			flex-direction: column;
		}
	}

	.social-icons-nested {
		display: flex;
		justify-content: center;
		flex: 1 1 auto;

		@include tablet {
			justify-content: flex-start;
		}
	}

	.social-icons-item {
		font-size: 26px;
		vertical-align: middle;
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		@include desktop {
			font-size: 38px;
			margin-right: 40px;

		}

		a {
			color: $primary-color-invert;

			&:hover {
				color: $secondary;
			}
		}
	}



	.newsletter-signup-nested {
		display: none;
		flex: 0 1 650px;
		max-width: none;

		@include tablet {
			display: flex;
		}

		.newsletter-signup-title {
			color: $primary-color-invert;
			font-size: 16px;
			text-transform: uppercase;
			letter-spacing: .1em;
			text-align: center;
			margin-bottom: 10px;

			@include tablet {
				padding-right: 169px;
			}
			
			@include desktop {
				padding-right: 210px;
			}
		}    
	} 

	.newsletter-signup-button {
		padding: 13px 40px;
		letter-spacing: .1em;

		&::placeholder {
			opacity: .75;
			color: $black;
		}
		
		&::after {
			content: '';
		}

		@include tablet {
			max-width: 169px;
		}
		
		@include desktop {
			padding: 13px 60px;
			max-width: 210px;
		}

		&:hover {
			border-color: $black;
		}
	}

}

.site-footer {
	// padding-top: 10px;
}

.footer {
	width: 100%;
	padding-bottom: 0;

	@include until( $tablet ){
		display: block;

		.footer-logo-text,
		.footer-links {
			width: 80%;
			max-width: 310px;
			margin: 0 auto;
		}
	}

	@include tablet-only {
		.footer-logo-text {
			flex-basis: 45%;
		}
	}

	.footer-logo{
		margin-bottom: 30px;
		@include tablet {
			margin-bottom: 15px;

			& + {
				.copyright-info-desktop {
					margin-top: 60px;
				}
			}
		}
	}

	.footer-copy {
		margin: 0 auto 46px auto;
		@include tablet {
			margin: 0 0 20px 0;
		}
	}

	.footer-links {
		padding: 0;

		@include tablet {
			flex: 0 1 650px;
		}
		
		.footer-link {
			font-size: 14px;
			letter-spacing: .05em;
			text-transform: uppercase;
			text-align: left;
			margin-bottom: 20px;
			display: inline-block;
			color: $black;

			@include tablet {
				font-size: 16px;
			}

			&:hover {
				color: $secondary;
			}

		}
	}

	.newsletter-signup-footer {
		background: $primary-color;
		color: $primary-color-invert;
		padding: 24px 5% 32px;
		margin-bottom: 0;

		@include until ( $tablet ){
			// margin: 0 auto;
		}

		@include tablet {
			display: none;
		}
	}

	.newsletter-signup-text {
		padding: 0;
		margin-bottom: 20px;
	}

	h4.newsletter-signup-title {
		color: $primary-color-invert;
		margin-bottom: 16px;
	}

	.newsletter-signup-text {
		display: none;
	}

	.newsletter-signup-form {
		height: auto;
		flex-direction: column;
		align-items: center;
	}

	.newsletter-signup input.newsletter-signup-input {
		padding: 10px 20px;
		// margin-bottom: 16px;
		width: 100% !important;
		border-color: $white;
		height: auto;
		margin: 0;
	}



	.newsletter-signup-button {
		font-size: 13px;
		padding: 16px 58px;
	}
	
	.copyright-info-mobile {
		background: $black;
		color: $white;
		margin: 0;
		padding-top: 30px;
	}


} //end .footer

.newsletter-signup input.newsletter-signup-input {
	border-color: $white;
	font-family: 'Inconsolata', serif;
	font-weight: 700;
	font-size: 13px;
	text-align: center;
	box-shadow: none;
	width: 100%!important;

	&::placeholder {
	   opacity: .8;
	   color: $primary-color;
	}
}

.pathway-footer-stamp {
	background: $black;
	color: $white;
	padding-top: 10px;

	@include tablet {
		position: relative;
		height: 90px;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

}

.copyright-info-desktop {
	margin: 0 0 20px 0;
}

.copyright-clinic {
	@extend %body-2;
	font-size: 12px;
}

.copyright-info-mobile {
	padding-bottom: 20px;
	.copyright-clinic {
		margin-bottom: 0;
	}
}


.pathway-footer-stamp-logo svg path {
	fill: $white;
}

.social-bar, 
.footer {
	.newsletter-signup-input {
		&::placeholder {
			text-transform: uppercase;
		}
	}
}

.newsletter-form-message {
	color: $primary-color-invert;
}