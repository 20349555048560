.half-column-title {
	@include h2-bar;
}

.half-column-cta {
	@extend %button-secondary;
}

.half-column-container {
	@include mobileSidePadding;
}

.two-col-photo-container {
	@extend .is-fullhd;
}

.two-col-photo-columns {
	@extend .is-gapless;
}

.two-col-with-photo {
	padding: 20px 0 80px;
	min-height: 350px;

	.two-col-photo-col-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.two-col-photo-col {
		text-align: center;
	}

	@include tablet {
		padding: 116px 0 116px;
	}

	.two-col-photo-col-image {
		padding-right: 4%;
		padding-top: 4%;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 90%;
			height: 90%;
			background: $secondary;
			top: -4%;
			right: -4%;
			position: absolute;
			z-index: -1;
		}
	}

	.half-column-container {
		margin-top: 50px;
		width: 100%;

		@include tablet {
			padding: 0 5% 0;
		}
	}

	.half-column-title {
		margin-bottom: 22px;
	}

	.half-column-content {
		margin-top: 0;
		margin-bottom: 30px;
		max-width: 32em;
		width: 100%;
	}

	.half-column-cta {
		min-width: 176px;

		@include desktop {
			min-width: 234px;
		}
	}

	.two-col-photo-col-image {

		img {
			object-fit: cover;
			@include object-fit( cover );
			width: 100%;
			height: 100%;
		}
	}
}

.image-right.two-col-with-photo {

	@include tablet {
		.two-col-photo-col-image {
			order: 2;
		}
	}

	.two-col-photo-col-image.column {
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 4%;
		padding-left: 4%;

		&::before {
			top: initial;
			left: initial;
			bottom: -4%;
			left: -4%;
			top: initial;
			right: initial;
		}
	}
}
