.footer-marketing {
  &.modal {
    z-index: 1000;
  }

  .modal-background {
    opacity: .6;
  }

  .footer-marketing-container {
    background: $white;
    padding: 70px 30px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: calc( 100% - 20px );
      height: calc( 100% - 20px );
      border: solid 4px;
      border-color: $secondary;
      left: 10px;
      top: 10px;
    }
  }

  .footer-marketing-message {
    h1, h2, h3, h4 { 
      color: $primary-color;
    }
  }

  .modal-slide-close {
    right: 20px;
    top: 20px;
  }

  .footer-marketing-message {
    &::before {
      content: "";
      width: 80px;
      height: 4px;
      background-color: $secondary;
      display: block;
      margin: 0 auto 40px;
    }
  }
    
  .three-col-img-image {
    margin: 0 auto 20px;
  }

}
  