.three-col-contact {
    padding: 66px 32px 46px;
    text-align: center;

    @include tablet {
        padding-top: 66px;
        padding-bottom: 80px;
    }

    @include desktop {
        padding-left: 0;
        padding-right: 0;
    }

    .three-col-contact-section-title {
		margin-bottom: 2em;
	}
	
	.three-col-contact-banner {
		@extend %body-1;
		margin-bottom: 40px;
		max-width: 54em;
		padding-left: $side-padding;
		padding-right: $side-padding;
		margin-left: auto;
		margin-right: auto;

		@include tablet {
			margin-bottom: 80px;
		}
	}

    .three-col-contact-container {
        @include h2-bar;
        padding-top: 36px;

        &::before {
            width: 74px;
        }

        @include tablet {
            padding-top: 0;

            &::before {
                content: none;
            }
        }
    }

    .three-col-contact-group {
        margin-bottom: 12px;
    }

    .three-col-contact-disclaimer {
        max-width: 48em;
        margin: 20px auto 0;

        @include tablet {
            margin-top: 80px;
        }
    }
}