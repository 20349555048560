.emergency-info {
  @include mobileSidePadding;
  padding-top: 30px;
  padding-bottom: 60px;

  @include tablet {
    padding-top: 110px;
    padding-bottom: 110px;
  }

  @include until ( $tablet ){
    .einfo-list-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .einfo-list {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: auto;
    }
  }

  .einfo-intro {
    padding-bottom: 26px;

    @include tablet {
      padding-bottom: 82px;
    }
  }


 .einfo-item p{
   position: relative;
   padding-left: 30px;

   &::before, &::after {
    content: "";
    position: absolute;
   }

   &::after {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
     width: 20px;
     height: 20px;
     border-radius: 50%;
     background-color: $secondary;
   }

   &::before {
     width: 7px;
     height: 12px;
     border-bottom: solid 2px;
     border-right: solid 2px;
     border-color: $secondary-invert;
     z-index: 2;
     left: 6px;
     top: calc(50% - 7px);
     transform: translateY(-50%);
     transform: rotate(45deg);
   }
 }

}