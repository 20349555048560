#landingpage {
  .module-forms .form-intro-container {
    padding-top: 40px;

    @include tablet {
      padding-top: 60px;
      margin-bottom: 80px;
    }
  }

  .generic-content {
    padding-top: 32px;

    @include tablet {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

}