.emergency-three-col {
  background: $primary-color;
  @include mobileSidePadding;
  padding-top: 50px;
  padding-bottom: 32px;

  @include desktop {
    padding-top: 150px;
    padding-bottom: 70px;
  }

  .e3col-intro {
    margin-bottom: 20px;
    h2, .e3col-intro-text, .e3col-intro-text a, .e3col-intro-text h3 {
      color: $primary-color-invert;
    }

    @include desktop {
      margin-bottom: 74px;
      h2 {
        margin-bottom: 26px;
      }
    }
  }

  .e3col-column {
    @include until( $desktop ){
      padding-bottom: 0px;
    }
  }

  .e3col-content-wrap {
    padding: 32px 12px 24px;
    background-color: $white;

    @include until( $desktop ){
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      h2, h3, h4, p {
        text-align: center;
      }
    }

    @include tablet {
      padding-left: 24px;
      padding-right: 24px;
    }

    @include desktop {
      height: 100%;
    }
  }

  .e3col-content {
    h3 {
      color: $primary-color;
    }
  }

}
