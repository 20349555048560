.background-pattern {
    padding: 44px 0 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../../img/bg-paws.png');
    background-repeat: repeat;
    background-position: top left;
    background-color: $neutral-lt;
    background-size: auto 600px;
    margin: 0;
}


%link-hover {
	position: relative;
	display: inline-block;

	&::after {
		content: '';
		position: absolute;
		display: block;
		width: 0;
		height: 1px;
		bottom: -2px;
		left: 0;
		background-color: currentColor;
		transition: width .2s;
		left: 50%;
		transform: translateX( -50% ); 
	}
	&:hover::after {
		width: 100%;
	}
}


// @function leftQuoteSVG($fillColor:#F26A3E ){
//     $friendlyColor: '%23' + str-slice('#{$fillColor}', 2, -1);
//     @return 'data:image/svg+xml;charset=utf-8,<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 59 38" enable-background="new 0 0 59 38" xml:space="preserve"><path fill="#{$friendlyColor}" d="M0.6,37.1V17.4l7-16.7h14.4l-4.5,16.1h8.7v20.3H0.6z M32.7,37.1V17.4l7-16.7h14.4l-4.5,16.1h8.7v20.3H32.7z"/></svg>';
// }


.theme-1-hr {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    text-align: center;

    .p-icon {
        font-size: 16px;
        background: $secondary;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        padding: 10px 2px;
        margin: 0 10px;
        color: $white;
    }

    &::before, &::after {
        content: '';
        display: block;
        width: 84px;
        height: 1px;
        background: $secondary;

        @include tablet {
            width: 120px;
        }
    }
}