.module-forms {

	.form-intro-container {
		@include mobileSidePadding;
		padding-top: 80px;

		@include tablet {
			padding-top: 120px;
		}
	}

	.form-wrapper {
		background: $tertiary-light;
		padding-top: 40px;
		padding-bottom: 80px;

		@include tablet {
			padding-top: 80px;
			padding-bottom: 120px;
		}
	}
	

	.column {
		margin-bottom: 10px;

		@include tablet {
			margin-bottom: 28px;
		}
	}

	.form__divider.column {
		margin-top: 40px;
		margin-bottom: 6px;
		
		@include until( $tablet ){
			padding-left: 0;
			padding-right: 0;
			margin-left: -12px;
			margin-right: -12px;
			h2 {
				text-align: left;
			}
		}

		@include tablet {
			margin-top: 95px;
			margin-bottom: 22px;
		}

		&:first-child {
			margin-top: 40px;
		}
	}

    
    .label {
        @extend %button-text;
        color: $black;
        font-size: 14px;
    
        @include tablet {
            font-size: 16px;
        }

        &.textarea-label {
            font-weight: 400;
            color: $neutral-dk;
        }
    }
    
	.control .input, 
	.control .textarea, 
	select, 
	.datetimepicker-dummy-wrapper {
        border-width: 1px;
        border-color: $black;
	}

	.control > .button {
		@extend %button-secondary;
		width: 100%;

		@include tablet {
			width: auto;
			min-width: 200px;
		}
	} 

	.form__submitbtn .control .button {
		@extend %button-primary;

		@include tablet {
			min-width: 320px;
		}
	}

	.form__submitbtn {
		@include tablet {
			margin-top: 40px;

			.control {
				text-align: center;
			}
		}
	}

	.form__checkboxterms {
		margin-top: 40px;
		p {
			@extend %body-2;
		}
		p, label {
			color: $neutral-dk;

		}
	}

	.datetimepicker {
		.datepicker-nav-previous,
		.datepicker-nav-next {
			color: $white;
			padding: 10px 20px;

			&:hover {
				color: $primary-color;
			}
		}
	}

	.select,
	.select select,
	.datetimepicker-dummy .datetimepicker-clear-button {
		color: $primary-color;
	}

	// .datetimepicker-dummy.is-gray::before, 
	// .datetimepicker-dummy::before {
	// 	background-color: $primary-color;
	// }

	// .datetimepicker-footer {
	// 	button.button {
	// 		@extend %hover-highlight;

	// 		&.datetimepicker-footer-cancel:hover {
	// 			color: $neutral-dk;
	// 		}
	// 	}
	// }


	.datepicker_input {
		background: $white;
		padding: 7px 11px 7px 4rem;
		border-width: 1px;
	}

	.form__datepicker {
		.control {
			&::before {
				left: 10px;
			}
		}
	}


	.form__checkboxterms {
		text-align: center;

		p {
			text-align: left;
		}
	}

	.checkbox {
		align-items: center;

		.help {
			margin-bottom: 0;
		}
	}

	@include until( $tablet ){
		.form__divider.form-accordion-header {
			background: transparent;
			color: $primary-color; 
			padding-left: 16px;
			padding-right: 42px;

			&::after {
				right: 16px;
			}

			h2 {
				font-size: 28px;
				margin-bottom: 24px;
			}

			&.accordion-closed {
				&::before {
					content: '';
					display: block;
					position: absolute;
					bottom: -23px;
					left: -25%;
					width: 150%;
					height: 4px;
					background: $white;
				}

				h2 {
					margin-bottom: 0;
				}
			}
		}

		.form-accordion-group{
			position: relative;
			
			&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: -23px;
			left: -25%;
			width: 150%;
			height: 4px;
			background: $white;	
			}
		}
	}

	.form__checkbox {
		margin-top: 40px;
	}

	.filepond--panel-root {
		background: #fff;
		opacity: .65;
		border: solid 1px;
		border-color: $black;
		border-radius: 0;
	}
	
	 .filepond--item-panel{
		background: $primary-color;
		border-radius: 0;
	}

}
 

	// .datetimepicker-footer {
	// 	.button {
	// 		padding-right: 20px;
	// 		&::after {
	// 		content: none;
	// 		}
	// 	}
	// }

    // .submit {
    //     text-align: center;
    // }


    // .module-button {
    //     @extend %button-primary;
    //     width: 90%;
    //     max-width: 320px;
    // }

    // .input, .textarea {
    //         &::placeholder{
    //         color: transparent;
    //     }
    // }

    // .input {
    //     height: 46px;
    // }

    // .module-label.textarea-label {
    //     color: $primary-color;
    //     font-weight: 700;
	// }
	
	// abbr {
	// 	margin-left: -.4em;
	// 	vertical-align: text-top;
	// }


    // .module-form-columns.columns {
    //     @extend .is-variable,
    //     .is-8-desktop;
    //     margin-bottom: 36px;

    //     @include tablet {
    //         margin-bottom: 70px;
    //     }

    //     @include widescreen {
    //         --columnGap: 54px;
    //     }
    // }

    // .module-field {
    //     margin-bottom: 0;

    //     @include tablet {
    //         margin-bottom: 18px;
    //     }
    // }