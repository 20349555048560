.teams-card-grid-container {
	@extend .is-variable,
	.is-6-widescreen,
	.is-8-fullhd;
}

.team-cards {
	padding: 65px 0;

	@include tablet {
		padding: 130px 0 126px;
	}

	.team-cards-title {
		margin-bottom: 20px;
		@include mobileSidePadding;

		@include tablet {
			margin-bottom: 100px;
		}
	}
	
	.team-card {
		@include until( $tablet ){
			height: 100%;
		}
	}

	.card-grid-container.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	.team-name {
			font-size: 32px;
	}

	.team-title {
			font-size: 17px;
	}

	.team-card-content {
		padding-bottom: 26px;
		border: solid 1px;
		border-color: $black;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;

		@include tablet {
			padding-bottom: 36px;

			h3, h4, p, a {
				padding: 0 32px;
			}
		}

		.team-photo {
			width: 100%;
			height: 202px;
			border-bottom: solid 1px;
			border-color: $black;

			@include tablet {
				height: 280px;
			}

			img {
				object-fit: cover;
				object-position: 50% 0;
				@include object-fit( cover, 50% 0 );
				width: 100%;
				height: 100%;

			}
		}

		.team-name {
			margin-top: 36px;

			@include tablet {
				margin-top: 0;
			}
		}

		.team-name, .team-title {
			margin-bottom: 12px;

			@include tablet {
				margin-bottom: 16px;
			}
		}

		.team-description {
			margin-bottom: 24px;

			@include tablet {
				margin-bottom: 40px;
			}
		}

		.modal-slide-button {
			padding-top: 0;
		}
	}

	.team-photo {
		border-radius: 0;
	}

	.slick-dots {
		bottom: -36px;
	}

	.slick-slide {
		margin: 0;
	}

	.slick-list {
		margin: 0;
	}
	
}

.leadership-card {
	@extend .is-12-tablet,
	.is-12-desktop,
	.is-12-widescreen;
}

.leadership-cards {
	background: $primary-color-light;
	padding: 52px 0 50px;

	@include tablet {
		padding: 130px 0 85px;
	}

	.leadership-cards-title {
		text-transform: none;
		margin-bottom: 50px;

		@include tablet {
			margin-bottom: 62px;
		}
	}

	.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	.leadership-card {

		@include tablet-only {
			padding-left: $side-padding;
			padding-right: $side-padding;
		}

		@include tablet {
			margin-bottom: 70px;
		}
	}

	.leadership-card-content {
		padding: 0;

		@include tablet {
			display: flex;
			flex-flow: row nowrap;
			border: solid 1px;
			border-color: $black;
			max-width: 1060px;
			margin: 0 auto;
		}

		.leadership-photo {
			margin: 0 auto; 
			width: 100%;
			height: 100vw;
			max-width: 474px;
			max-height: 474px;
			border: solid 1px;
			border-color: $black;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: 50% 0;
				@include object-fit( cover );
			}

			@include tablet {
				width: 34%;
				height: auto;
				min-height: 400px;
				max-width: none;
				max-height: none;
				margin: 0;
				padding: 0;
				border: none;
				border-right: solid 1px;
				border-right-color: $black;
			}
		}

		.leadership-text {
			width: 80%;
			max-width: 380px;
			margin: -26px auto 0;
			background: $white;
			border: solid 1px;
			border-color: $black;
			padding: 40px 24px 34px;
			position: relative;
			z-index: 10;

			h3, h4, p, a {
				padding: 0;
			}

			@include tablet {
				width: 66%;
				margin: 0; 
				padding: 50px;
				max-width: none;
				max-height: none;
				border: none;

				.leadership-description {
					max-width: none;
					padding-left: 0;
				}
			}
		}

		.leadership-name, 
		.leadership-title {
			margin-bottom: 14px;

			@include tablet {
				margin-bottom: 26px;
			}
		}

		.leadership-name {
			text-transform: none;
		}

		.leadership-description {
			margin-bottom: 34px;

			@include tablet {
				margin-bottom: 58px;
			}
		}


	}

	.slick-dots {
		margin-top: 0;
		bottom: -34px;
	}

}

//Modal popup styling here
.team-cards, .leadership-cards {

	a.link {
		@extend %link-hover;
		padding: 0;
	}

	@include until( $tablet ){
		.modal-slide {
			padding: initial;
		}

		.modal-background {
			cursor: pointer;

			&::before {
				font-family: 'pathway-icons';
				content: "\e870";
				font-size: 20px;
				line-height: 1;
				position: absolute;
				display: block;
				width: 20px;
				height: 20px;
				top: 10px;
				right: 20px;
				color: $white;
				z-index: 100;
			}

			&:hover::before {
				color: $secondary;
			}
		}
	}

	.modal-text {
		@include until( $tablet ){
			position: relative;
			margin: -26px 16px 0;
			padding: 36px 30px 42px;
			background: $white;
			border: solid 1px;
			border-color: $black;
			z-index: 10;
		}
	}

	.team-photo-modal, 
	.leadership-photo-modal {
		border: solid 1px;
		border-color: $black;
		
		@include until( $tablet ){
			width: 100%;
			height: 330px;
			margin: 0;
			z-index: 0;
		}

		@include tablet {
			width: 400px;
			height: 400px;
		}
	}

	.modal-slide-close {
		top: 16px;
		right: 16px;
		z-index: 10;
	}

	.modal-slide {
		position: relative;

		@include tablet {
			padding-left: 70px;
			padding-right: 70px;
			
			&::after {
				content: "";
				position: absolute;
				display: block;
				border: solid 6px;
				border-color: $secondary;
				width: calc( 100% - 12px );
				height: calc( 100% - 12px );
				top: 6px;
				right: 6px;
				z-index: 0;
			}
		}
	}

	.team-title-modal, .leadership-title-modal {
		@include tablet {
			margin-bottom: 30px;
		}
	}

	.team-name-modal,
	.team-title-modal,
	.leadership-name-modal,
	.leadership-title-modal {
		@include until( $tablet ){
			margin-bottom: 12px;
		}
	}


	.team-description-modal, .leadership-description-modal {
		@include tablet {
			text-align: left;
			position: relative;
			padding-top: 60px;

			&::before {
				content: "";
				position: absolute;
				width: 78px;
				height: 6px;
				background: $secondary;
				top: 0;
				left: 50%;
				transform: translateX( -50% );
			}
		}
	}

	.modal-slide-close {
		@include until( $tablet ) {
		   display: none;
		}
	}

	.card-grid-container {
		.slick-arrow {
			display: none !important;
		}
	}

	.lightbox-modal {
		background: none;
    }
    

    .group-name {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;

        @include tablet {
            font-size: 32px;
        }
    
}
}
