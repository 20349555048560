.blog-featured {
    padding: 60px 0 42px;

    @include tablet {
        padding: 128px 0 116px;
    }

    .blog-featured-title {
        margin-bottom: 16px;

        a {
            color: $primary-color;
        }

        @include tablet {
            margin-bottom: 48px;
        }
    }

    .blog-featured-image-wrap {
        max-width: 834px;
        max-height: 440px;
        margin: 0 auto;
    }

    .blog-featured-caption {
        max-width: 744px;
        margin: 0 auto;
        background: none;
        padding: 24px 28px 0;

        @include tablet {
            padding: 46px 0 0;
        }
    }

    .blog-featured-excerpt {
        margin-bottom: 24px;

        @include tablet {
            margin-bottom: 42px;
        }
    }

    .blog-featured-date {
        display: none;
    }

    .blog-featured-link.link {
        @extend %button-secondary;
        height: auto;
        width: auto;
        min-width: 176px;
        display: inline-block;
        padding-top: 18px;
        padding-bottom: 18px;

        @include tablet {
            min-width: 270px;
        }
    }

}