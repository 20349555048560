.event-item {
	@extend .is-full-tablet,
	.is-full-desktop;
	padding-top: 0;
}

.communityEvents {
    background: $primary-color-light;
    padding: 0;

    @include tablet {
        padding: 130px 0 40px;
    }

    .event-item-content {
        display: flex;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 80px;
        }
    }

    .event-thumbnail {
        margin-left: auto;
        margin-right: auto;
        border: solid 1px;
        border-color: $black;
        width: 100%;
        position: relative;

        @include tablet {
            border-right: none;
        }

        @include tablet-only {
            flex: 0 0 40%;
        }
    
        @include desktop {
            flex: 0 0 34%;
            min-height: 400px;
        }

    }

    .event-image {
        display: block;
        width: 100%;

        @include tablet {
            object-fit: cover;
			object-position: 50% 0;
			@include object-fit( cover, 50% 0 );
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .event-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background: $white;
        border: solid 1px;
        border-color: $black;
        padding: 37px 30px 32px;
        margin: -14px 5% 35px;
        z-index: 10;

        @include tablet {
            margin: 0;
            text-align: left;
            align-items: flex-start;
            padding: 52px 50px 48px 44px;
        }
    }

    .event-title {
        order: 1;
        @extend %h3;
        margin-bottom: 32px;

        @include tablet {
            margin-bottom: 26px;
        }
    }

    .event-link-1 {
        @extend %button-text;
        order: 5;

        @include tablet {
            display: none;
        }
    }

    .event-date {
        order: 2;
        @extend %page-title;
        margin-bottom: 16px;

        @include until( $tablet ){
            font-size: 12px;
        }

        @include tablet {
            margin-bottom: 28px;
        }
    }

    .event-add-calendar, .event-add-calendar-title {
        @extend %button-text;
        font-size: 12px;
        color: $primary-color;

        @include tablet {
            font-size: 14px;
        }
        
    }

    .event-add-calendar {
        order: 3;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        color: $secondary;
        margin-bottom: 28px;
        position: relative;
        overflow: hidden;

        @include tablet {
            order: 4;
            margin-bottom: 0;
			width: 100%;
			justify-content: flex-start;
        }

        @include tablet-only {
            padding-left: 160px;
            padding-bottom: 2em;
        }

        & a, h4 {
            @include tablet {
                margin-bottom: 0;
            }
        }

        a {
            flex: 1 1 30%;
            color: $secondary;
            position: relative;
            padding: 5px 0;
            border-right: solid 1px;
            border-right-color: $neutral-lt;
            margin-right: -1px;


            @include tablet {
                flex: 0 1 auto;
                text-align: center;
                padding: 4px 15px;
            }

            &:last-of-type {
                border-right: none;
            }

            &:hover {
                color: $primary-color;
            }
        }
    }

    .event-add-calendar-title {
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Lato', sans-serif;
        text-transform: none;
        color: $neutral-dk;
        font-weight: 400;
        margin-bottom: 14px;

        &::before {
            font-family: 'pathway-icons';
            content: '\e922';
            font-size: 120%;
            padding-right: 10px;
            color: $secondary;
            top: -1px;
            position: relative;
        }

        @include tablet {
            flex: 0 1 auto;
			align-items: flex-start;
			justify-content: flex-start;
        }

        @include tablet-only {
            position: absolute;
            left: 0;
            top: 2px;
            width: 160px;
        }
    }

    .event-link-2 {
        @include until( $tablet ){
            display: none;
        }
    }

    p.event-content {
        order: 4;
        @extend %body-1;
        max-width: 38em;
        margin: 0 0 26px;

        @include tablet {
            max-width: none;
            order: 3;
            margin-bottom: 30px;
        }
    }


}
