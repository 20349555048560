.homeHero-container-theme2 {
	position: relative;
    padding: 0 0 70px;
	margin: 0;
	
	
	.hero-carousel-card {
		width: 100%;
	}

    .homeHero-image {
        @include maximize-width;
        height: 460px;
        background-size: cover;
        background-position: top center;

        @include tablet {
            height: 660px;
        }

        @include desktop {
            height: 860px;
        }
    }

    .homeHero-text-container {
        padding: 38px 35px 25px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX( -50% );
        width: 90%;
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
        background: $white;
        border: solid 2px;
        border-color: $secondary;
        box-shadow: 0 0 0 4px $white;
        text-align: center;

        @include tablet {
            padding: 54px 35px 50px;
            border-width: 5px;
            box-shadow: 0 0 0 8px $white;
        }
    }

    .homeHero-title {
        padding: 20px 0;
        margin-bottom: 14px;
        position: relative;

        @include tablet {
            padding: 80px 0 52px;
            margin-bottom: 62px;
        }

        span {
            display: block;
        }
        .homeHero-title-large {
            margin-bottom: 14px;

            @include tablet {
                margin-bottom: 30px;
            }
        }

        .homeHero-title-small {
            @extend %h4;
        }
        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            width: 1em;
            height: 2px;
            background: $secondary;
            left: 50%;
            transform: translateX( -50% );
            margin-left: auto;
            margin-right: auto;

            @include tablet {
                height: 5px;
            }
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }
    }

    .homeHero-button {
        @extend %button-primary;
        margin: 0 auto;

        @include tablet {
            min-width: 320px;
        }
    }

}