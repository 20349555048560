.one-col-promo-container {
	@extend .container;
	
	@include tablet-only {
		padding-left: $side-padding;
		padding-right: $side-padding;
	}
}

.one-col-promo-cta {
	@extend %button-primary;
	background: $primary-color;
	border-color: $primary-color;
}

h2.one-col-promo-title {
	@include h2-bar;
	padding-bottom: 1rem;
}

.one-col-promo {
	padding: 50px 0 0 0;
	background: $primary-color-light;

	.one-col-promo-container {
		display: flex;
		flex-direction: column;
		text-align: center;


		@include tablet {
			flex-direction: row;
			justify-content: space-around;
		}
	}

	.one-col-promo-image {
		order: 2;
		display: block;
		width: 80%;
		max-width: 610px;
		height: auto;
		margin: 0 auto;

		img {
			display: block;
		}

		@include tablet {
			order: 1;
			margin: 0;
			align-self: flex-end;
		}
	}

	.one-col-promo-content {
		@include mobileSidePadding;
		padding-top: 0;
		padding-bottom: 20px;
		border: none;
		background: none;
		text-align: center;

		@include tablet {
			order: 2;
			align-self: center;
			margin-left: 5%;
			margin-bottom: 20px;
		}
	}
	
	h2.one-col-promo-title {
		letter-spacing: initial;
	}

	.one-col-promo-body {
		margin: 0 auto 26px;
		max-width: 28em;
	}

	.one-col-promo-cta {
		min-width: 184px;
		max-width: 90%;
		margin-top: 0;

		@include tablet {
			min-width: 252px;
		}
	}

	@include desktop {
		.one-col-promo {
			padding: 0;
		}
		.one-col-promo-container {
			padding: 0;
		}
	}
}
