.one-col-info {
	@include mobileSidePadding;
	background: none;
	color: $neutral-dk;
	padding-top: 38px;
	padding-bottom: 50px;
	text-align: center;

	@include tablet {
		padding: 100px $side-padding;
	}

	.one-col-info-container {
		padding: 64px 24px 50px;
		border: solid 1px; 
		border-color: $black;
		max-width: 1200px;
		margin: 0 auto;

		@include tablet {
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	.one-col-info-content {

	}

	.one-col-info-title {
		@include h2-bar;
		margin-bottom: 24px;
		color: $primary-color;

		&::before {
			color: $secondary;
		}

		@include tablet {
			margin-bottom: 38px;
		}
	}

	.one-col-info-body {
		max-width: 42em;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 40px;

		@include tablet {
			max-width: 48em;
			margin-bottom: 50px;
		}
	}

	.one-col-info-cta {
		@extend %button-secondary;
		margin-top: 0;
		min-width: 176px;

		@include tablet {
			min-width: 300px;
		}
	}
}

.with-bg .one-col-info, .one-col-info.with-bg {
	padding-top: 50px;
	padding-bottom: 50px;
	background: $secondary;
	color: $secondary-invert;


	// @include tablet-only {
	//	 padding-left: $side-padding;
	//	 padding-right: $side-padding;
	// }

	@include tablet {
		padding: 105px 0;
	}

	@include desktop {
		padding: 160px 0;
	}

	.one-col-info-container {
		border: none;
		background: none;
		padding: 0;
		margin: 0;
		max-width: none;

		@include tablet {
			padding: 0;
		}
	}

	.one-col-info-title {
		color: $secondary-invert;

		&::before {
			background: $secondary-invert;;
		}
	}

	.one-col-info-body {
		@include tablet {
			margin-bottom: 40px;
		}
	}

	.one-col-info-cta {
		@extend %button-outlined-white;

	}
}

.aboutpage .one-col-info ~ .one-col-info:not(.with-bg) {
	padding-top: 0;
	padding-bottom: 20px;

	.one-col-info-container {
		border: none;

		@include tablet {
			padding-bottom: 80px;
		}
	}
}
 