.module-blog-grid {
	background: $tertiary-dark;
	color: $white;
	padding: 45px 0 36px;

	@include tablet {
		padding: 120px 0 30px;
	}

	.container {
		margin-bottom: 66px;
	}

	.blog-grid-block-link {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.blog-grid-card {
		margin-bottom: 0;
	}

	.blog-grid-title {
		padding-left: $side-padding;
		padding-right: $side-padding;
		color: $white;
		margin-bottom: 44px;

		@include tablet {
			margin-bottom: 70px;
		}
	}

	.blog-grid-card-image {
		border-top: none;

		&.blog-card-no-hero {
			@include until( $tablet ){
				padding-top: 100px;
			}
		}
	}

	.blog-grid-card-content {
		padding: 36px 40px 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: auto;
		border: none;
	}

	.blog-grid-date {
		@extend %button-text;
		color: $black;
		font-size: 10px;
		margin-bottom: 26px;

		@include tablet {
			font-size: 20px;
		}
	}

	h3.blog-grid-post-title {
		text-transform: none;
		font-size: 27px;
		margin-bottom: 28px;
	}

	.blog-grid-link {
		margin-bottom: 0;
		color: $secondary;

		&:hover {
			color: $black;
			text-decoration: underline;
		}
	}

	@include until( $tablet ){

		.blog-grid-card {
			background: $white;
			width: 90%;
			max-width: 450px;
			margin: 0 auto;
			padding: 16px; 
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;

			&::after{
				font-family: "pathway-icons";
				content: "\e876";
				display: block;
				flex: 0 0 auto;
			}

			&:hover {
				background: $primary-color-light;
			}
		}
	
		.blog-grid-card-image {
			flex: 0 0 25%;
			width: 25%;
			padding-top: 25%;
		}

		.blog-grid-card-content {
			flex: 1 1 auto;
			padding: 0 8px 0 16px;
			text-align: left;
		}

		.blog-grid-date {
			margin-bottom: 8px;
		}

		h3.blog-grid-post-title {
			margin: 0;
			font-size: 18px;
		}

		.blog-grid-link {
			display: none;
		}
	}//end tablet-only


}