.flash-message {
	background: $primary-color;
	color: $primary-color-invert;
	text-transform: capitalize;
	padding: 24px 20px;

	@include desktop {
		padding: 14px;
		align-items: center;
	}


	.flash-message-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	
	.flash-message-content {
		margin: 0 40px;
		text-align: center;


		p, a, h2, h3, h4 {
			color: $primary-color-invert;
			line-height: 1.5;
		}

		p {
			@extend %h5;
		}

		@include until ( $tablet ){
			margin: 0 30px;
			p {
				font-size: 12px;
			}
		}
		
		a {
			text-decoration: underline;
			&:hover {
				text-shadow: -1px 1px 1px rgba( 0, 0, 0, .15), 
				1px 1px 1px rgba( 0, 0, 0, .15), 
				1px -1px 1px rgba( 0, 0, 0, .15), 
				-1px -1px 1px rgba( 0, 0, 0, .15);
				cursor: pointer;
			}
		}
	}

	.flash-message-close {
		@extend .modal-slide-close;
		border: none;
		background: transparent;
		align-self: center;
		top: initial;
		right: 0;
		width: 16px;
		height: 16px;

		&::after, &::before {
			background: $primary-color-invert;
			left: 4px;
			width: 16px;
		}

		&:hover {
			&::after, &::before {
				background: $secondary;
			}
		}
	}

}
