.three-col-img-columns {
	@extend .is-1;
}

.three-col-with-image {
		padding: 58px 24px 50px;

	@include tablet {
		padding: 84px 0 100px;
		text-align: center;
	}

	.three-col-img-title {
		margin-bottom: 28px;

		@include desktop {
			margin-bottom: 32px;
		}
	}

	.three-col-img-intro {
		color: $black;
		max-width: 50em;
		padding: 0;
		margin: 0 auto 20px;

		@include desktop {
			margin-bottom: 90px;
		}
	}

	.three-col-img-columns {
		justify-content: center;

		@include until($desktop){
			display: block;
		}
	}

	.three-col-img-column {
		display: flex;
        flex-direction: row;
				align-items: center;
				height: auto;

		@include until($desktop){
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
			padding: 34px 0;
			border-bottom: solid 1px;
			width: 100%;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
			border-color: $neutral-lt;
			text-align: left;
			&:last-of-type {
				border-bottom: none;
			}
		}

		@include desktop {
			align-items: center;
			flex-direction: column;
		}
	}

	.three-col-img-content {
		width: 100%;
		padding: 0;
		margin: 0 0 0 15%;
		flex: 2 1 65%;
		height: auto;
		width: 100%;
		@include desktop {
			text-align: center;
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between; 
			flex: 1 1 auto;
		}
	}

	.three-col-img-subtitle {
		color: $secondary;
		margin-bottom: 6px;
		width: 100%;
		@include desktop {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.three-col-img-body  {
		// max-width: 28em;
		margin: 0 0 34px;
		width: 100%;
		@include desktop {
			max-width: 28em;
			padding-left: 25px;
			padding-right: 25px;
		}
	}

	.three-col-img-body, .three-col-img-body p {
		@extend %body-2;
	}

	.three-col-img-cta {
		@extend %button-secondary;
		width: 100%;
		max-width: 234px;
	}

}

.three-col-img-image{
	flex: 1 0 48px;
	font-size: 72px;

 @include desktop {
	 width: 65px;
	 margin-bottom: 12px;
	 flex: 0 0 auto;
 }

 .p-icon {
	 color: $secondary;
 }
}
