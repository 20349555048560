.services-list-cta {
	// @extend $butt
}

.services-list-card-body {
	@extend %body-1;
}

.services-list-card-grid-container {
	@extend .is-8-desktop;
}

h2.services-list-title {
	@include h2-bar;
}

.services-list-title, 
.services-list-description {
	@include mobileSidePadding;
}

.services-list-card-body {
	@extend %body-2;
}


.services-list {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;

	@include tablet {
		padding-top: 82px;
		padding-bottom: 90px;
	}

	@include desktop {
		padding-top: 124px;
		padding-bottom: 130px;
	}

	p {
		color: $neutral-dk;
		margin-left: auto;
		margin-right: auto;
	}


	.services-list-title {
		margin-bottom: 20px;
	}

	.services-list-description {
		max-width: 36em;
		margin-bottom: 52px;

		@include tablet {
			margin-bottom: 60px;
		}
	}

	.card-grid-container {

		&.slick-slider {
			margin-bottom: 76px;
		}
	   
	}

	.services-list-column.column {
		@include until( $tablet ){
			padding: 0;
		}
	}

	.services-list-card {
		display: flex!important;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		@include until ($tablet){
			width: 100%;
			padding: 12px 12px 32px;
			border: solid 1px;
			border-color: $primary-color;
			transform: scale(.8);
			transform-origin: center center;
			transition: .3s;
		}

		@include tablet {
			margin-bottom: 53px;
			display: flex;
			flex-direction: column;
		}

	}

	.slick-slide {
		width: 240px;
		> div {
			height: auto;
		}
	}

	.slick-slide.slick-current {
		.services-list-card {
			transform: none;
		}
	}

	.services-list-card-title {
		margin-bottom: 26px;
	}

	.services-list-card-body {
		margin-bottom: 20px;
		max-width: 20em;
		padding-left: 14px;
		padding-right: 14px;
	}

	.services-list-card-cta {
		font-weight: 600;
		@include tablet {
			margin-top: auto;
		}
	}

	.services-list-icon {
		font-size: 40px;
		width: 60px;
		height: 60px;
		display: flex;
		margin-bottom: 22px;
		color: $secondary;
		border-radius: 50%;
		background: $primary-color-light;
		justify-content: center;
		align-items: center;

		@include tablet {
			font-size: 50px;
			width: 80px;
			height: 80px;
		}
	}

	.services-list-cta {
		@extend %link-hover;
		margin-top: 0;
		margin-left: auto;
		margin-right: auto;
		padding-left: 40px;
		padding-right: 40px;
	}

	.slick-arrow {
		display: none!important;
	}
	
	.slick-dots {
		bottom: -50px;
	}

}
