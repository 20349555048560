.one-col-promo-alt.one-col-promo {
	height: 475px;
	padding: 0;
	position: relative;

	@include tablet {
		height: 562px;
	}

	@include desktop {
		height: 650px;
	}

	.container {
		@include maximize-width;
		height: 100%;
	}

	.one-col-promo-container {
		width: 100%;
		height: 100%;
		position: relative;
		padding: 0;
		margin: 0;
		z-index: 1;
		color: $white;
		text-align: center;
		background: transparent;
		border: none;
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&::after {
			content:  '';
			top: 0;
			left: 0;
			display: block;
			background: black;
			opacity: .6;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: -1;
		}
	}

	.one-col-promo-image {
		position: absolute; 
		width: 100%;
		height: 100%;
		z-index: -5;
		top: 0;
		left: 0;
		max-width: none;
		img {
			width: 100%;
			height: 100%;
			object-position: 50% 0;
			object-fit: cover;
			@include object-fit( cover, 50% 0 );
		}
	}

	h2, h3, p, a {
		color:$white;
		//text-align: center;
		margin: 0 auto;
	}

	.one-col-promo-title {
		&::before {
			content: none;
		}
	}

	p.one-col-promo-body {
		max-width: 46em;
		margin-bottom: 3em;
	}

	.one-col-promo-cta {
		@extend %button-primary;
		border-color: $white;
	}

}